import { useGtm } from '@gtm-support/vue-gtm'

import {
  cloneLodash,
  debounceLodash,
  filterLodash,
  findIndexLodash,
  findLodash,
  reduceLodash,
} from '@/shared/js/lodash-helpers'
import { getItemKey, getProductFeedId } from '@/web/js/helpers'

const moduleBasket = {
  namespaced: true,
  state: {
    items: [],
    isRush: false,
    prices: {},
    rushPrice: 0,
    loading: false,
    route: '',
    cjRoute: '',
    currentProduct: {},
  },
  mutations: {
    setIsRush(state, isRush) {
      state.isRush = isRush
    },
    setItems(state, items) {
      state.items = items.map(item => {
        item.key = getItemKey(item)
        item.addedFrom = window.location.pathname
        return item
      })
    },
    addItem(state, item) {
      const itemKey = getItemKey(item)
      let itemIdx = findIndexLodash(state.items, { key: itemKey })

      if (itemIdx !== -1) {
        state.items[itemIdx].quantity++
      } else {
        item = cloneLodash(item)
        item.key = itemKey
        item.addedFrom = window.location.pathname
        state.items.push(item)
      }
    },
    changeItemSize(state, { item, sizeId }) {
      let sizeIdx = findIndexLodash(item.sizes, { id: parseInt(sizeId, 10) })
      let itemIdx = findIndexLodash(state.items, { key: item.key })

      if (sizeIdx !== -1 && itemIdx !== -1) {
        item.size = item.sizes[sizeIdx]
        item.key = getItemKey(item)
        const sameItems = filterLodash(state.items, { key: item.key })

        if (sameItems.length > 1) {
          item.quantity = reduceLodash(sameItems, (sum, i) => sum + i.quantity, 0)
          state.items.splice(itemIdx, 1, item)
          state.items = state.items.filter((i, index) => itemIdx === index || i.key !== item.key)
        } else {
          state.items.splice(itemIdx, 1, item)
        }
      }
    },
    changeItemAddon(state, { item, addonName, value }) {
      item[addonName] = value
      const itemIdx = findIndexLodash(state.items, { key: item.key })
      if (itemIdx !== -1) {
        state.items.splice(itemIdx, 1, item)
      }
    },
    changeItemQuantity(state, [item, quantity]) {
      item.quantity = quantity
      const itemIdx = findIndexLodash(state.items, { key: item.key })
      if (itemIdx !== -1) {
        state.items.splice(itemIdx, 1, item)
      }
    },
    items(state, value) {
      state.items = value
    },
    setCurrentProduct(state, value) {
      state.currentProduct = value
    },
    loading(state, value) {
      state.loading = value
    },
    removeItem(state, itemIdx) {
      state.items.splice(itemIdx, 1)
    },
    route(state, value) {
      state.route = value
    },
    prices(state, prices) {
      state.prices = prices || {}
    },
    rushPrice(state, price) {
      state.rushPrice = price || 0
    },
    cjRoute(state, value) {
      state.cjRoute = value
    },
  },
  getters: {
    items: state => state.items,
    isRush: state => state.isRush,
    isRushAvailable: state => state.items.some(item => item.isRushOrderingEnabled),
    rushPrice: state => state.rushPrice,
    loading: state => state.loading,
    route: state => state.route,
    currentProduct: state => state.currentProduct || {},
    addonPrices: state => ({
      addonExtraInnerPocket: state.prices?.addon_extra_inner_pocket || '0',
      addonDetachableZipLining: state.prices?.addon_detachable_zip_lining || '0',
    }),
    orderPrices: (state, getters) => {
      const addonExtraInnerPocket = parseFloat(getters.addonPrices.addonExtraInnerPocket)
      const addonDetachableZipLining = parseFloat(getters.addonPrices.addonDetachableZipLining)
      let totalPrice = 0
      let discount = 0
      let additions = 0

      state.items?.forEach(item => {
        const priceMod = item.size?.price_modifier ? item.size.price_modifier : 0

        totalPrice += (item.prices.retail + priceMod) * item.quantity
        discount += item.isOnSale ? (item.prices.retail - item.prices.sale) * item.quantity : 0

        if (item.addonExtraInnerPocket) {
          additions += addonExtraInnerPocket * item.quantity
        }

        if (item.addonDetachableZipLining) {
          additions += addonDetachableZipLining * item.quantity
        }
      })

      return {
        totalPrice,
        totalPriceAdditionsLess: totalPrice - additions,
        additions,
        discount,
        salePrice: totalPrice - discount,
      }
    },
  },
  actions: {
    fetchItems({ commit, getters }, { getRoute, cb }) {
      commit('loading', true)
      this.axios
        .get(getRoute)
        .then(async ({ data: { data, isRush } }) => {
          commit('setIsRush', isRush || false)
          await commit('setItems', data || [])

          if (getters.orderPrices.salePrice > 0 && typeof cb === 'function') {
            cb()
          }
        })
        .catch(err => console.error(err))
        .finally(() => commit('loading', false))
    },
    async addItem({ commit, dispatch, state }, { ...product }) {
      commit('loading', true)
      await commit('addItem', product)
      dispatch('storeData')

      const gtm = useGtm()

      if (gtm.enabled()) {
        gtm.trackEvent({
          event: 'add_to_cart',
          category: 'Cart',
          action: 'click',
          label: 'User added item to cart',
          noninteraction: false,
          currency: 'USD',
          value: product.prices.sale,
          items: [
            {
              item_id: getProductFeedId(product.sku, product.size ? product.size.name : ''),
              item_name: product.title,
              price: product.prices.sale,
              item_brand: 'VarsityBase',
              item_category: product.typename,
              quantity: product.quantity,
              variant: product.size ? product.size.name : '',
            },
          ],
        })
      }

      if (state.cjRoute) {
        this.cj.sitePage = {
          enterpriseId: 1564957,
          pageType: 'cart',
        }
        this.cjInit(state.cjRoute)
      }
    },
    async changeItemQuantity({ commit, dispatch, state }, [itemKey, quantity]) {
      commit('loading', true)
      const item = findLodash(state.items, { key: itemKey })

      if (item) {
        await commit('changeItemQuantity', [item, quantity])
        dispatch('storeData')
      }
    },
    async decreaseItemQuantity({ commit, dispatch, state }, itemKey) {
      commit('loading', true)
      const item = findLodash(state.items, { key: itemKey })

      if (item) {
        const quantity = item.quantity - 1

        if (quantity > 0) {
          await commit('changeItemQuantity', [item, quantity])
          dispatch('storeData')
        } else {
          commit('loading', false)
        }
      }
    },
    async increaseItemQuantity({ commit, dispatch, state }, itemKey) {
      commit('loading', true)
      const item = findLodash(state.items, { key: itemKey })

      if (item) {
        const quantity = item.quantity + 1

        await commit('changeItemQuantity', [item, quantity])
        dispatch('storeData')
      }
    },
    async removeItem({ commit, dispatch, state }, itemKey) {
      const itemIdx = findIndexLodash(state.items, { key: itemKey })
      const product = cloneLodash(state.items[itemIdx])

      await commit('removeItem', itemIdx)
      dispatch('storeData')

      const gtm = useGtm()

      if (gtm.enabled()) {
        gtm.trackEvent({
          event: 'remove_from_cart',
          category: 'Cart',
          action: 'click',
          label: 'User removed item from cart',
          noninteraction: false,
          currency: 'USD',
          value: product.prices.sale,
          items: [
            {
              item_id: getProductFeedId(product.sku, product.size ? product.size.name : ''),
              item_name: product.title,
              price: product.prices.sale,
              item_brand: 'VarsityBase',
              item_category: product.typename,
              variant: product.size ? product.size.name : '',
              quantity: product.quantity,
            },
          ],
        })
      }
    },
    async setItemSize({ dispatch, commit }, [item, sizeId]) {
      commit('loading', true)
      await commit('changeItemSize', { item, sizeId })
      dispatch('storeData')
    },
    async setItemAddon({ dispatch, commit }, { item, addonName, value }) {
      commit('loading', true)
      await commit('changeItemAddon', { item, addonName, value })
      dispatch('storeData')
    },
    async setIsRush({ dispatch, commit }, isRush) {
      commit('loading', true)
      await commit('setIsRush', isRush)
      dispatch('storeData')
    },
    setRushPrice({ commit }, price) {
      commit('rushPrice', price)
    },
    setRoute({ commit }, route) {
      commit('route', route)
    },
    setPrices({ commit }, prices = {}) {
      commit('prices', prices)
    },
    setCjRoute({ commit }, route) {
      commit('cjRoute', route)
    },
    storeData: debounceLodash(function ({ commit, getters, state }) {
      commit('loading', true)
      const isRush = getters.isRushAvailable ? state.isRush : false

      this.axios
        .post(state.route, { basketItems: state.items, isRush })
        .then(async ({ data: { status, data, isRush } }) => {
          if (status === 'success') {
            commit('setItems', data || [])
            commit('setIsRush', isRush || false)
          }
        })
        .catch(err => console.log('storeData catch', err))
        ?.finally(() => commit('loading', false))
    }, 1000),
    setCheckoutGaData({ state, getters }) {
      const gtm = useGtm()

      if (!gtm.enabled()) {
        return
      }

      gtm.trackEvent({
        event: 'begin_checkout',
        category: 'Checkout - stripe',
        action: 'click',
        label: 'Checkout using stripe initiated',
        noninteraction: false,
        currency: 'USD',
        value: getters.orderPrices.salePrice,
        items: state.items.map(item => {
          return {
            item_id: getProductFeedId(item.sku, item.size.name),
            item_name: item.title,
            price: item.prices.sale,
            item_brand: 'VarsityBase',
            item_category: item.typename,
            google_business_vertical: 'retail',
            quantity: item.quantity,
            variant: item.size.name,
          }
        }),
      })
    },
  },
}

export default moduleBasket
